<template>
  <div class="container">
    <div class="jumbotron1 jumbotron-fluid">
      <div class="container">
        <h1 class="display-4">{{ loggedUser.username }}</h1>
        <p class="lead">{{ loggedUser.email }}</p>
      </div>
    </div>
    <div class="jumbotron2 jumbotron-fluid">
      <div class="container">
        <form v-on:submit.prevent="putPassword()" id="form">
          <h3>Alterar Password</h3>
          <div class="form-group">
            <label for="password">Password Antiga*</label>
            <input
              type="password"
              class="form-control"
              id="password"
              placeholder="Password"
              required
              v-model="passwordOld"
            />
          </div>
          <div class="form-group">
            <label for="newPassword">Nova Password*</label>
            <input
              type="password"
              class="form-control"
              id="newPassword"
              placeholder="Confirmar Password"
              minlength="8"
              required
              v-model="newPassword"
            />
          </div>
          <div class="form-group">
            <label for="confPassword">Confirmar Password*</label>
            <input
              type="password"
              class="form-control"
              id="confPassword"
              placeholder="Confirmar Password"
              minlength="8"
              required
              v-model="confPassword"
            />
          </div>
          <small>*Campos obrigatórios</small> <br />
          <button type="submit" class="btn btn-primary float-center">Alterar Password</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import router from '../router';

export default {
  data: function () {
    return {
      loggedUser: '',
      passwordOld: '',
      newPassword: '',
      confPassword: '',
      token: '',
    };
  },

  created() {
    this.getUserInformation();
  },
  methods: {
    getUserInformation() {
      this.token = localStorage.getItem('token');
      this.loggedUser = JSON.parse(localStorage.getItem('user'));
      console.log('USER USER');
    },
    async putPassword() {
      try {
        await this.$store.dispatch('updatePassword', {
          id: this.token,
          oldPassword: this.passwordOld,
          newPassword: this.newPassword,
          newPassword2: this.confPassword,
        });
        this.$swal({
          icon: 'success',
          title: 'Alterada',
        });

        router.push('/');
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: err,
        });
      }
    },
  },
};
</script>
<style scoped>
* {
  text-align: center;
}
.container {
  margin-top: 50px;
  border-radius: 5px;
}
.jumbotron1 {
  background-color: pink;
  border-radius: 5px 5px 0px 0px;
  border: 3px solid pink;
}
.jumbotron2 {
  border-radius: 0px 0px 5px 5px;
  border: 3px solid pink;
}
.jumbotron1 .container h1,
p {
  text-align: center;
}
.form-group {
  margin: 10px;
}
.form-group label {
  color: pink;
}

button {
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
