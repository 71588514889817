<template>
  <div>
    <ProfileCard />
  </div>
</template>
<script>
import ProfileCard from "@/components/ProfileCard.vue";
export default {
  components: {
    ProfileCard,
  },
};
</script>
